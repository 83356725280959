import React from 'react'
import Layout from '../components/LayoutView'
import { graphql, Link } from 'gatsby'

import icon22 from '../../content/assets/img/icons/22.png'
import PageHeader from '../components/common/pageHeader'

const Pricing = ({ data }) => {
  const { nodes } = data.allContentfulPricing
  return (
    <Layout title='thank-you'>
      
      <div>
        <div className='user-list-area pd-top-90 pd-bottom-70'>
          <div className='container'>
            <div className='section-title text-center'>
              <h2 className='title'>Thank You for Contacting Us</h2>
            <p>We would be in touch shortly, meanwhile you speak with an agent on standby immediately in the live chat. </p>
            <p>Click the blue button below.</p>
            </div>
            {/*<div className='row'>
              {nodes.map((item, i) => (
                <div key={i} className='col-lg-3 col-sm-6'>
                  <div className='single-pricing text-center'>
                    <div className='thumb double-img'>
                      <img src={icon22} alt='image' />
                    </div>
                    <div className='details'>
                      <h4 className='title'>{item.name}</h4>
                      <h3 className='price'>{item.price}</h3>
                      <h6>{item.time}</h6>
                      <ul>
                        {item.feature.map((featuresitem, i) => (
                          <li key={i}>{featuresitem}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>*/}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPricing {
      nodes {
        price
        name
        time
        feature
      }
    }
    heroImage: contentfulHeroBadge(title: { eq: "pricing" }) {
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default Pricing
